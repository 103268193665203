<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      Exam Type Form
    </template>
    <template slot="body">
      <FormText
        v-model="name"
        label="Name"
        @keydownEnter="saveExamType"
      />
    </template>
    <template slot="footer">
      <md-button
        class="md-success md-sm"
        @click="saveExamType"
      >
        Save
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import {
  FormText,
} from '@/components/Inputs';

export default {
  components: {
    FormText,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    name: null,
  }),
  mounted() {
    if (this.item) {
      this.name = this.item.name;
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    saveExamType() {
      if (this.item) {
        this.request(`exam_types/${this.item.exam_type_id}`, 'put', { name: this.name }, () => {
          this.fireSuccess('Exam Type updated successfuly');
          this.$emit('onSaved');
          this.close();
        });
      } else {
        this.request('exam_types', 'post', { name: this.name }, () => {
          this.fireSuccess('Exam Type created successfuly');
          this.$emit('onSaved');
          this.close();
        });
      }
    },
  },
};
</script>
<style></style>
