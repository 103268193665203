<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <md-button
          class="md-just-icon md-round md-primary pull-right"
          @click="newExam"
        >
          <md-icon>add</md-icon>
          <md-tooltip>New Exam Type</md-tooltip>
        </md-button>
        <vue-table
          ref="vtable"
          :values="vTable.values"
          :headers="vTable.headers"
          :actions="vTable.actions"
          :options="{
            tableClass:'table'
          }"
          @changed="getData"
          @onEdit="onEdit"
          @onRemove="onRemove"
        />
      </md-card-content>
    </md-card>
    <ExamTypesModal
      v-if="showModal"
      :item="selectedExamType"
      @close="close"
      @onSaved="$refs.vtable.init()"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import ExamTypesModal from './ExamTypesModal.vue';

export default {
  components: { VueTable, ExamTypesModal },
  data: () => ({
    showModal: false,
    loading: false,
    selectedExamType: null,
    vTable: {
      headers: [
        {
          title: 'name',
          sortable: true,
        },
      ],
      values: {},
      actions: [
        {
          buttonClass: 'md-info',
          tooltip: 'Edit Exam Type',
          callback: 'onEdit',
          icon: 'edit',
        },
        {
          buttonClass: 'md-danger',
          tooltip: 'Remove Exam Type',
          callback: 'onRemove',
          icon: 'close',
        },
      ],
    },
  }),
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getData(params) {
      this.loading = true;
      this.request('exam_types', 'get', { ...params, paginate: true }, ({ data }) => {
        this.vTable.values = data;
      }, () => {
        this.loading = false;
      });
    },
    close() {
      this.showModal = false;
      this.selectedExamType = null;
    },
    newExam() {
      this.showModal = true;
      this.selectedExamType = null;
    },
    onEdit(item) {
      this.showModal = true;
      this.selectedExamType = item;
    },
    onRemove(item) {
      this.request(`exam_types/${item.exam_type_id}`, 'delete', {}, () => {
        this.fireSuccess('Exam Type deleted successfuly');
        this.$refs.vtable.init();
      });
    },
  },
};
</script>

<style>

</style>
